<template>
  <b-sidebar
    id="add-new-document-type-sidebar"
    :visible="isAddNewDocumentTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-document-type-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }} Product Variant (Color)</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Color-->
            <validation-provider
              #default="validationContext"
              name="Color"
              rules="required"
            >
              <!-- <b-form-group label="Color" label-for="color">
                <b-form-input
                  id="color"
                  v-model="documenttypeData.color"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Color"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group> -->

              <b-form-group label="Color" label-for="color">
                <b-input-group>
                  <b-form-input
                    id="color"
                    v-model="documenttypeData.color"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-input-group-append is-text>
                    <el-color-picker
                      v-model="documenttypeData.color"
                      size="mini"
                    ></el-color-picker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Remarks-->
            <b-form-group label="Remarks" label-for="remarks">
              <b-form-input
                id="remarks"
                v-model="documenttypeData.remarks"
                autofocus
                trim
                placeholder="Remarks"
              />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
                <!-- {{ button_text }} -->
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, onMounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewDocumentTypeSidebarActive",
    event: "update:is-add-new-document-type-sidebar-active",
  },
  props: {
    isAddNewDocumentTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  methods: {},
  setup(props, { emit }) {
    console.log(props);
    const loading = ref(false);
    const licenseAuthorityOptions = ref([]);
    const userOptions = ref([]);

    const blankDocumentTypeData = {
      id: 0,
      is_active: 1,
    };
    const button_text = ref("Add");

    const documenttypeData = ref(
      JSON.parse(JSON.stringify(blankDocumentTypeData))
    );

    const resetdocumenttypeData = () => {
      documenttypeData.value = JSON.parse(
        JSON.stringify(blankDocumentTypeData)
      );
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewDocumentTypeSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewDocumentTypeSidebarActive) {
          documenttypeData.value = { ...props.data };

          if (documenttypeData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }
        } else {
          button_text.value = "Add";
        }
      }
    );

    const onSubmit = () => {
      console.log(documenttypeData);
      loading.value = true;
      store
        .dispatch("product-color/updateColor", documenttypeData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-document-type-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetdocumenttypeData
    );

    onMounted(() => {});

    return {
      documenttypeData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
      licenseAuthorityOptions,
      userOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-document-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
